export const environment = {
  production: true,
  database: 'dev',
  firebaseEnv: {
    apiKey: 'AIzaSyC9APnYPTOBlctD_TTJBaFffWH-1DHnvRQ',
    authDomain: 'rewirenpc-dev.firebaseapp.com',
    databaseURL: 'https://rewirenpc-dev.firebaseio.com',
    projectId: 'rewirenpc-dev',
    storageBucket: 'rewirenpc-dev.appspot.com',
    messagingSenderId: '738711015646'
  },
  draftModeUrl: 'https://npc-dev-rewire.firebaseapp.com/app/' // Dev Student
};
